<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import type { Slots } from 'vue';

type Props = {
    adjustHeightToContent?: boolean;
    closeModalOnClickOutside?: boolean;
    fullHeight?: boolean;
    fullScreen?: boolean;
    padding?: string;
    show?: boolean;
    showCloseButton?: boolean;
    width?: string;
    margin?: string;
};

const props = withDefaults(defineProps<Props>(), {
    adjustHeightToContent: false,
    closeModalOnClickOutside: true,
    fullHeight: false,
    fullScreen: false,
    margin: '',
    padding: '',
    show: false,
    showCloseButton: true,
    width: '',
});

const emit = defineEmits(['close']);

const showModal = ref(props.show);
const modal = ref(null);
const container: Ref<HTMLElement | null> = ref(null);

const closeModal = () => {
    if (showModal.value === false) return;

    showModal.value = false;
    emit('close');
};

onClickOutside(modal, () => {
    if (props.closeModalOnClickOutside) closeModal();
});

const slots: Slots = useSlots();
const hasFooterSlot = computed(() => slots.footer);

watch(
    () => props.show,
    (newValue: boolean) => {
        showModal.value = newValue;
        if (!showModal.value) {
            emit('close');
        }
    },
);

const escapeHandler = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && props.showCloseButton) {
        closeModal();
    }
};

onMounted(() => {
    window?.addEventListener('keydown', escapeHandler);
});

onUnmounted(() => {
    window?.removeEventListener('keydown', escapeHandler);
});
</script>

<template>
    <Teleport to="body">
        <Transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200 transform"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="showModal"
                ref="modal-backdrop"
                class="fixed inset-0 z-50 bg-black bg-opacity-[.40]"
            >
                <div
                    ref="container"
                    class="absolute inset-x-0 text-center"
                    :class="[
                        {
                            'inset-y-[5vh] overflow-y-auto':
                                !fullScreen && !fullHeight,
                            'inset-y-0': fullHeight,
                            'h-full': fullScreen,
                            BaseModalContainerHidden: !showModal,
                        },
                        margin,
                    ]"
                >
                    <Transition
                        enter-active-class="transition ease-out duration-300 transform"
                        enter-from-class="opacity-0 translate-y-10 scale-95"
                        enter-to-class="opacity-100 translate-y-0 scale-100"
                        leave-active-class="ease-in duration-200"
                        leave-from-class="opacity-100 translate-y-0 scale-100"
                        leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
                    >
                        <div
                            v-if="showModal"
                            ref="modal"
                            class="relative mx-auto max-w-full bg-white text-left"
                            :class="[
                                {
                                    'overflow-hidden rounded': !fullScreen,
                                    'overflow-y-auto': fullScreen,
                                    'w-full md:w-[46rem]':
                                        !fullScreen && !width,
                                    'h-full': !adjustHeightToContent,
                                },
                                width,
                                padding,
                            ]"
                            role="dialog"
                        >
                            <slot :close-modal="closeModal" />
                            <button
                                v-if="showCloseButton"
                                class="absolute right-5 top-5 w-6"
                                data-testid="BaseModal closebutton"
                                @click="closeModal"
                            >
                                <BaseIcon icon-name="close" size="large" />
                            </button>
                            <div
                                v-if="hasFooterSlot"
                                class="absolute bottom-0 left-0 w-full bg-white p-5 shadow-top"
                            >
                                <div
                                    class="flex justify-between gap-5 md:justify-end"
                                >
                                    <slot name="footer"></slot>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>
